var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"dialog",staticClass:"edit-data-stream-dialog"},[_vm._m(0),_c('div',{staticClass:"description-container"},[_c('div',{staticClass:"name filter"},[_vm._m(1),_c('div',{staticClass:"filter-value"},[_c('NioTextField',{attrs:{"rules":[_vm.rules.required],"label":"Data Stream Name","validate-on-blur":""},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1)]),_c('div',{staticClass:"description filter"},[_vm._m(2),_c('div',{staticClass:"filter-value"},[_c('NioTextarea',{attrs:{"rules":[_vm.rules.required],"rows":3,"placeholder":"Data Stream Description","validate-on-blur":""},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})],1)]),_c('div',{staticClass:"image filter"},[_vm._m(3),_c('div',{staticClass:"filter-value"},[_c('NioTextField',{attrs:{"label":"Data Stream Image URL","validate-on-blur":""},model:{value:(_vm.model.image),callback:function ($$v) {_vm.$set(_vm.model, "image", $$v)},expression:"model.image"}})],1)]),_c('div',{staticClass:"category filter"},[_vm._m(4),_c('div',{staticClass:"filter-value"},[_c('NioTextField',{attrs:{"label":"Data Stream Category"},model:{value:(_vm.model.category),callback:function ($$v) {_vm.$set(_vm.model, "category", $$v)},expression:"model.category"}})],1)]),_c('div',{staticClass:"tags filter"},[_vm._m(5),_c('div',{staticClass:"filter-value"},[_c('NioTagsField',{attrs:{"label":"Add Tags"},model:{value:(_vm.model.tags),callback:function ($$v) {_vm.$set(_vm.model, "tags", $$v)},expression:"model.tags"}})],1)])]),_c('div',{staticClass:"actions"},[_c('NioButton',{attrs:{"normal-secondary":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c('NioButton',{attrs:{"normal-primary":"","disabled":!_vm.valid},on:{"click":_vm.save}},[_vm._v("Save")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header step-header"},[_c('h2',{staticClass:"nio-h2 text-primary-darker"},[_vm._v("Edit Data Stream")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-description"},[_c('div',{staticClass:"filter-title nio-h4 text-primary-darker"},[_vm._v("Name")]),_c('div',{staticClass:"description nio-p text-primary-dark"},[_vm._v("The name of the data stream as displayed to customers.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-description"},[_c('div',{staticClass:"filter-title nio-h4 text-primary-darker"},[_vm._v("Description")]),_c('div',{staticClass:"description nio-p text-primary-dark"},[_vm._v("Provide information about this data stream to customers. If your Data Shop is public, we'll add a JSON-LD product schema to help buyers discover your data stream on search engines.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-description"},[_c('div',{staticClass:"filter-title nio-h4 text-primary-darker"},[_vm._v("Image")]),_c('div',{staticClass:"description nio-p text-primary-dark"},[_vm._v("Provide a product image to showcase the data stream.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-description"},[_c('div',{staticClass:"filter-title nio-h4 text-primary-darker"},[_vm._v("Category")]),_c('div',{staticClass:"description nio-p text-primary-dark"},[_vm._v("Provide an optional category for the data stream.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-description"},[_c('div',{staticClass:"filter-title nio-h4 text-primary-darker"},[_vm._v("Tags")]),_c('div',{staticClass:"description nio-p text-primary-dark"},[_vm._v("Add tags for discoverability on your sales channels.")])])
}]

export { render, staticRenderFns }